// const dotenv = require('dotenv');
// dotenv.config(); // ścieżka do pliku .env

// const apiUrl = process.env.API_LINK;
// const apiName = process.env.WP_NAME;
// const apiKey = process.env.WP_PASS;

// console.log(apiKey,apiName,apiUrl)

// użyj zmiennych dbHost, dbUser i dbPass w swoim kodzie JavaScript

document.addEventListener('DOMContentLoaded', function () {

    // Get variables:
    const formMainWrapper = document.getElementsByClassName('custom-lead');

    if (formMainWrapper) {

        [...formMainWrapper].forEach(element => {
            const customForm = element.getElementsByTagName('form')[0];
            const inputCustomUrl = element.querySelector('input[name="custom-url"]'); // ok
            const inputSource = element.querySelector('input[name="source"]'); // ok
            const inputMedium = element.querySelector('input[name="medium"]');
            const inputCampaign = element.querySelector('input[name="campaign"]');
            const inputCampaignDate = element.querySelector('input[name="campaign-date"]');
            const inputDate = element.querySelector('input[name="date"]'); // ok
            const inputAccept = element.querySelector('input[name="accept"]');
            const inputEmail = element.querySelector('input[name="email"]');
            const inputPhone = element.querySelector('input[name="phone"]');
            const inputName = element.querySelector('input[name="name"]');
            const inputSurname = element.querySelector('input[name="surname"]');
            const infoBox      = element.querySelector('.custom-lead__left-form-info');
            const textareaContent = element.querySelector('textarea[name="content"]')
            const urlParams = getURLParams();
            const thisForm = element;
            let emailOrPhone = '';

            if(element.classList.contains('only-email-or-phone')) {
                emailOrPhone = element;
            }
            if (customForm) {
                customForm.addEventListener('submit', function (el) {
                    el.preventDefault();

                    let status = true;
                    let sourceText = '';
                    let firstName = '';
                    let secondName = '';
                    let phoneOrEmailError = true;
                    let errorMessage = '';
                    let emailValue = '';
                    let phoneValue = '';
                    let content = '';

                    if (inputDate) {
                        inputDate.value = getCurrentDateTime();
                    }

                    if (inputName) {
                        let validateName = validateNameAndSurname(inputName.value);
                        if (validateName === false) {
                            inputName.classList.add('error');
                            inputPhone.classList.remove('success');
                            console.log('Name Error');
                            status = false;
                        } else {
                            inputName.classList.remove('error');
                            inputName.classList.add('success');
                        }
                    }

                    if (textareaContent) {
                        textareaContent.classList.remove('error');
                        textareaContent.classList.add('success');
                    }

                    if(textareaContent) {
                        if(textareaContent.value) {
                            content = textareaContent.value;
                        }
                    }

                    if (inputSurname) {
                        let validateSurname = validateNameAndSurname(inputSurname.value);
                        if (validateSurname === false) {
                            console.log('Surname Error');
                            inputSurname.classList.add('error');
                            inputPhone.classList.remove('success');
                            status = false;
                        } else {
                            inputSurname.classList.remove('error');
                            inputSurname.classList.add('success');
                        }
                    }
                    if(inputSource) {
                        if(inputSource.value) {
                            sourceText = inputSource.value;
                        } else {
                            sourceText = 'brak';
                        }
                    }

                    if(emailOrPhone && inputEmail.value.length < 1 && inputPhone.value.length < 1) {
                        phoneOrEmailError = false;
                    } else {
                        if(inputEmail) {
                            if(inputEmail.value) {
                                emailValue = inputEmail.value;
                                let validateEmailField = validateEmail(inputEmail.value);
                                if(validateEmailField === false) {
                                    console.log('Email Error');
                                    inputEmail.classList.add('error');
                                    inputPhone.classList.remove('success');
                                    status = false;
                                } else {
                                    inputEmail.classList.remove('error');
                                    inputEmail.classList.add('success');
                                }
                            }
                        }
        
                        if(inputPhone) {
                            if(inputPhone.value) {
                                let validatePhone = validatePhoneNumber(inputPhone.value);
                                phoneValue = inputPhone.value;
                                if(validatePhone === false) {
                                    console.log('Phone Error');
                                    inputPhone.classList.add('error');
                                    inputPhone.classList.remove('success');
                                    status = false;
                                } else {
                                    inputPhone.classList.remove('error');
                                    inputPhone.classList.add('success');
                                }
                            }
                        }
                        phoneOrEmailError = true;
                    }

                    if(inputName) {
                        if(inputName.value) {
                            firstName = inputName.value;
                        }
                    }

                    if(inputSurname) {
                        if(inputSurname.value) {
                            secondName = inputSurname.value;
                        }
                    }

                    if(phoneOrEmailError === false) {
                        errorMessage = 'Adres email lub numer telefonu jest polem wymaganym.';
                        thisForm.classList.add('custom-lead-errors');
                    } else if(status === false) {
                        errorMessage = 'Formularz zawiera błędy.';
                        thisForm.classList.add('custom-lead-errors');
                    }


                    if(infoBox) {
                        if(status === false || phoneOrEmailError === false) {
                            infoBox.classList.add('error');
                            infoBox.classList.remove('success');
                            infoBox.classList.remove('hidden');
                            infoBox.innerText = errorMessage;
                        } else {
                            infoBox.classList.add('success');
                            infoBox.classList.remove('error');
                            infoBox.classList.remove('hidden');
                            if(infoBox.dataset.successInfo !== undefined) {
                                infoBox.innerText = infoBox.dataset.successInfo;
                            } else {
                                infoBox.innerText = 'Dziękujemy za przesłanie formularza, odpowiemy najszybciej jak jest to możliwe.'
                            }
                        }
                    }

                    console.log(status);

                    if(status === true && phoneOrEmailError === true) {
                        thisForm.classList.remove('custom-lead-errors');
                        const data = {
                            url: inputCustomUrl.value,
                            zrodlo: sourceText,
                            medium: inputMedium.value,
                            kampania: inputCampaign.value,
                            email: emailValue,
                            numer_telefonu: phoneValue,
                            data_kampanii: inputCampaignDate.value,
                            data_leada: inputDate.value,
                            imie: firstName,
                            nazwisko: secondName,
                            zgoda: 'TAK',
                            content: content
                        };
                
                        addNewLead(data);
                    }

                })
            }

            if (inputCustomUrl) {
                inputCustomUrl.value = getCurrentPageLink();
            }

            if (urlParams !== false) {
                if (urlParams.utm_source) {
                    if (inputSource) {
                        inputSource.value = urlParams.utm_source;
                    }
                }
                if (urlParams.utm_medium) {
                    if (inputCampaign) {
                        inputMedium.value = urlParams.utm_medium;
                    }
                }
                if (urlParams.utm_campaign) {
                    if (inputCampaign) {
                        inputCampaign.value = urlParams.utm_campaign;
                    }
                }
            }
            function validatePhoneNumber(phoneNumber) {
                const phoneRegex = /^(\+?\d{1,3}[- ]?)?\d{9,11}$/;
                const isValidPhoneNumber = phoneRegex.test(phoneNumber);
                
                return isValidPhoneNumber;
            }
        
            function validateEmail(email) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const isValidEmail = emailRegex.test(email);
                
                return isValidEmail;
            }
        
            function clearForm() {
                setTimeout(() => {
                    if(inputPhone) {
                        inputPhone.classList.remove('success');
                        inputPhone.value = '';
                    }
                    if(inputEmail) {
                        inputEmail.classList.remove('success');
                        inputEmail.value = '';
                    }
                    if(inputName) {
                        inputName.classList.remove('success');
                        inputName.value = '';
                    }
                    if(textareaContent) {
                        textareaContent.classList.remove('success');
                        textareaContent.value = '';
                    }
                    if(inputSurname) {
                        inputSurname.classList.remove('success');
                        inputSurname.value = '';
                    }
                    if(infoBox) {
                        infoBox.classList.add('hidden');
                        infoBox.innerText = '';
                    }
                }, 5000);
            }
        
            // Get website url:
            function getCurrentPageLink() {
                try {
                    let link = window.location.href;
                    if (link.indexOf("?") !== -1) { // sprawdzenie, czy adres zawiera znak zapytania
                        link = link.substring(0, link.indexOf("?")); // usunięcie parametrów z adresu strony
                    }
                    return link;
                } catch (error) {
                    console.log("Nie można uzyskać adresu strony: ", error);
                    return 'Brak';
                }
            }
        
            // Generate date
            function getCurrentDateTime() {
                const now = new Date();
                const date = `${now.getDate().toString().padStart(2, "0")}-${(now.getMonth() + 1).toString().padStart(2, "0")}-${now.getFullYear()}`;
                const hours = now.getHours().toString().padStart(2, "0");
                const minutes = now.getMinutes().toString().padStart(2, "0");
                const seconds = now.getSeconds().toString().padStart(2, "0");
                const time = `${hours}:${minutes}:${seconds}`;
                return `${date} ${time}`;
            }
        
            // Generate URL params
            function getURLParams() {
                const params = new URLSearchParams(window.location.search);
                if (params.toString() === "") { // sprawdzenie, czy nie ma parametrów w adresie strony
                    return false;
                }
                const result = {};
                for (const [key, value] of params) {
                    result[key] = value;
                }
                return result;
            }
        
            // Validate name:
        
            function validateNameAndSurname(name) {
                const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]+$/;
                return name.trim() !== "" && regex.test(name);
            }
        
            function addNewLead(data) {
                const endpoint = 'https://hermes.bezpiecznarodzina.pl/wp-json/wp/v2/leads';
                const username = 'mptaszek';
                const application_password = 'U5vJ Oz6C wgVy AVbQ Hn1G WFKE';
                const auth = btoa(username + ':' + application_password);
        
                const headers = {
                    'Authorization': 'Basic ' + auth,
                    'Content-Type': 'application/json',
                };
        
                const body = JSON.stringify({
                    title: data.imie + ' ' + data.nazwisko, // tytuł postu
                    status: 'publish',
                    acf: { // pola ACF
                        url: data.url,
                        zrodlo: data.zrodlo,
                        medium: data.medium,
                        kampania: data.kampania,
                        email: data.email,
                        numer_telefonu: data.numer_telefonu,
                        data_kampanii: data.data_kampanii,
                        data_leada: data.data_leada,
                        imie: data.imie,
                        nazwisko: data.nazwisko,
                        zgoda: data.zgoda,
                        content: data.content
                    }
                });
        
                fetch(endpoint, {
                    method: 'POST',
                    headers: headers,
                    body: body
                })
                    .then(response => {
                        if (!response.ok) {
                            console.log(response);
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        clearForm();
                    })
                    .catch(error => {
                        if(inputPhone) {
                            inputPhone.classList.remove('success');
                        }
                        if(inputEmail) {
                            inputEmail.classList.remove('success');
                        }
                        if(inputName) {
                            inputName.classList.remove('success');
                        }
                        if(textareaContent) {
                            textareaContent.classList.remove('success');
                        }
                        if(inputSurname) {
                            inputSurname.classList.remove('success');
                        }
                        if(infoBox) {
                            infoBox.classList.add('error');
                            infoBox.classList.remove('success');
                            infoBox.innerText = 'Wystąpił nieoczekiwany błąd, prosimy spróbować później.';
                        }
                        console.error('Wystąpił błąd:', error);
                    });
            }
        });

    }

})